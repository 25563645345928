import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ms-icon',
  templateUrl: './fabric-base-icon.component.html',
  styleUrls: ['./fabric-base-icon.component.scss']
})
export class FabricBaseIconComponent implements OnInit {
  @Input('icon')
  IconName: string;
  get iconClass() {
    return 'ms-Icon ms-Icon--' + this.IconName || '';
  }
  constructor() {}

  ngOnInit() {}
}
