<ng-template>
  <div
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="_onAnimationDone($event)"
  >
    <mat-card class="mat-elevation-z6 flex w-72 flex-col !p-0">
      <div fxHide></div>
      <mat-calendar
        class="-mt-4"
        [selected]="viewDate$ | async"
        [maxDate]="max"
        [minDate]="min"
        [startAt]="(viewDate$ | async)?.start"
        (_userSelection)="this.selectedChange($event)"
      ></mat-calendar>
    </mat-card>
  </div>
</ng-template>
