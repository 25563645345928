import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { startOfWeek, endOfWeek } from 'date-fns/esm';
type DateRangeStrategyType = 'week' | 'day';
@Injectable()
export class DateRangeSelectionStrategy<D = Date> implements MatDateRangeSelectionStrategy<D> {
  private rangeType: DateRangeStrategyType = 'day';
  setRangeType(type: DateRangeStrategyType) {
    this.rangeType = type;
  }
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null, currentRange: DateRange<D>): DateRange<D> {
    const type = this.rangeType;
    if (type === 'week') return this._createWeekRange(date);
    else if (type === 'day') return this._createDayRange(date, currentRange);
    return null;
  }

  private _createDayRange(date: D, currentRange: DateRange<D>) {
    return new DateRange<D>(date, date);
  }
  createPreview(activeDate: D | null, currentRange: DateRange<D>) {
    if (this.rangeType === 'week') return this._createWeekRange(activeDate);
    let start: D | null = null;
    let end: D | null = null;

    if (currentRange.start && !currentRange.end && activeDate) {
      start = currentRange.start;
      end = activeDate;
    }

    return new DateRange<D>(start, end);
  }

  private _createWeekRange(_date: D | null): DateRange<D> {
    if (_date) {
      const date = new Date(_date as any);
      const start = startOfWeek(new Date(date), { weekStartsOn: 1 });
      const end = endOfWeek(new Date(date), { weekStartsOn: 1 });
      return new DateRange<D>(start as any, end as any);
    }

    return new DateRange<D>(null, null);
  }
}
